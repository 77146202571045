import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export default function Theme() {
  const theme = createTheme({       
    typography: {
      fontFamily: [
        'Share Tech',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell'
      ].join(','),         
      body1: {
        fontFamily: "'Share Tech', Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell",
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              textTransform: 'none',
              border: `10px dashed red`
            },
          },              
        ],
      },
    },
  });

  return responsiveFontSizes(theme);
}
