import config from "../config"

export const updateNfts = async (setNfts, projectId, publicKey) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                publicKey: publicKey
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setNfts(reqJson.data?.nfts)
        else setNfts([])
    } catch (err) {
        console.log(err)
        setNfts([])
    }
}

export function nFormatter(num, digits) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    if(num < 1) return num
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? ((num) / item.value) + item.symbol : "0";
}
