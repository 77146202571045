import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { useContext, useEffect, useState } from 'react'
import { TraitContext } from '../App'
import { applyTrait, delistTrait, listTrait } from '../javascript/traitShop'
import './../css/traitShop.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
export default function MyTraits() {
    const { myTraits, collection } = useContext(TraitContext)

    const [listedTraitsToShow, setListedTraitsToShow] = useState()
    const [applyTrait, setApplyTrait] = useState()
    const [listingTrait, setListingTrait] = useState()
    const [delistingTrait, setDelistingTrait] = useState()
    useEffect(() => {
        if (myTraits) {
            let traits = []
            myTraits.forEach((x, i) => {
                const traitInCollection = collection.traitCategories.find(y => y.category === myTraits[i].traitCategory)?.traits?.find(y => y.traitValue === myTraits[i].traitValue)

                let traitCategory = traits.find(x => x.traitCategory === myTraits[i].traitCategory)
                if (!traitCategory) {
                    traits.push({
                        traitCategory: myTraits[i].traitCategory,
                        traits: []
                    })
                    traitCategory = traits.find(x => x.traitCategory === myTraits[i].traitCategory)
                }
                let traitValue = traitCategory.traits.find(x => x.traitValue === myTraits[i].traitValue)
                if (!traitValue) {
                    traitCategory.traits.push({
                        traitId: traitInCollection.traitId,
                        traitValue: myTraits[i].traitValue,
                        owned: [],
                        image: traitInCollection.image
                    })
                    traitValue = traitCategory.traits.find(x => x.traitValue === myTraits[i].traitValue)
                }
                traitValue.owned.push(myTraits[i])
            })
            setListedTraitsToShow(traits)
        }
    }, [myTraits, collection.traitCategories])


    if (!listedTraitsToShow) {
        return (<div></div>)
    } else if (!listedTraitsToShow.length) {
        return (
            <div className='utility__centered-text'>
                You don't own any trait
            </div>
        )
    } else {
        return (
            <>
                <ApplyTrait trait={applyTrait} setApplyTrait={setApplyTrait} />
                <ListTraitModal trait={listingTrait} setListTrait={setListingTrait} />
                <DelistTraitModal trait={delistingTrait} setDelistTrait={setDelistingTrait} />

                <div className='trait-shop__listed-traits-categories__container'>
                    {listedTraitsToShow.map((x, i) => {
                        return (
                            <div className='trait-shop__listed-trait-category__container' key={i}>
                                <div className='trait-shop__listed-trait-category__title'>{x.traitCategory}</div>
                                <div className='trait-shop__listed-traits__container'>
                                    {x.traits.map((x) => {
                                        const newTraits = { ...x }
                                        const owned = x.owned.filter(y => !y.listed?.status)
                                        newTraits.owned = owned
                                        return newTraits
                                    }).map((y, i) => {
                                        if (y.owned.length) {
                                            return (
                                                <div key={i} className='trait-shop__listed-trait__container'>
                                                    <div style={{ position: 'relative' }}>
                                                        <img className='trait-shop__listed-trait__img' src={y.image} alt={y.traitValue} />
                                                        {y?.owned?.length > 1 ? <div className='trait-shop__my-trait__number-of-items'>{y.owned?.length}</div> : (<></>)}
                                                    </div>
                                                    <div className='trait-shop__listed-trait__info-container'>
                                                        <div className='trait-shop__listed-trait__name'>{y.traitValue}</div>
                                                        <div className='trait-shop__listed-trait__info-box'>
                                                            <button className='trait-shop__hover-animated-btn' onClick={() => setApplyTrait(y)}>
                                                                <span>Apply</span>
                                                            </button>
                                                            <button className='trait-shop__hover-animated-btn' onClick={() => setListingTrait(y)}>
                                                                <span>List</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (<div key={i}></div>)
                                        }
                                    })}

                                    {x.traits.map(x => {
                                        const newTraits = { ...x }
                                        const owned = x.owned.filter(y => y.listed?.status)
                                        newTraits.owned = owned
                                        return newTraits
                                    }).map((y, i) => {
                                        if (y.owned.length) {
                                            return (
                                                <div className='trait-shop__listed-trait__container' key={i}>
                                                    <div style={{ position: 'relative' }}>
                                                        <img className='trait-shop__listed-trait__img' src={y.image} alt={y.traitValue} />
                                                        {y?.owned?.length > 1 ? <div className='trait-shop__my-trait__number-of-items'>{y.owned?.length}</div> : (<></>)}
                                                    </div>
                                                    <div className='trait-shop__listed-trait__info-container'>
                                                        <div className='trait-shop__listed-trait__name'>{y.traitValue}</div>
                                                        <div className='trait-shop__listed-trait__info-box'>
                                                            <button className='trait-shop__hover-animated-btn' onClick={() => setDelistingTrait(y)}>
                                                                <span>Delist</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (<div key={i}></div>)
                                        }
                                    })}

                                </div>
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }
}

const ListTraitModal = ({ trait, setListTrait }) => {
    const [numberOfTraits, setNumberOfTraits] = useState(1)
    const [price, setPrice] = useState()
    const { collection, refresh } = useContext(TraitContext)
    const [disableBtn, setDisableBtn] = useState(false)
    const { publicKey } = useWallet()
    if (trait) {
        return (
            <div className='modal-wrapper'>
                <div className='modal-bg' onClick={() => setListTrait()}></div>
                <div className='modal-container'>
                    <div className='modal'>
                        <form id='trait-shop__list-trait-form' className='utility__form' onSubmit={(event) => event.preventDefault()}>
                            <div className='field required'>
                                <div className='label'>Number of traits to list</div>
                                <input className='text-input' value={numberOfTraits} type='number' onChange={(event) => {
                                    setNumberOfTraits(Number(event.target.value))
                                }} />
                            </div>

                            <div className='field required'>
                                <div className='label'>Price {`(SOL)`}</div>
                                <input className='text-input' value={price} onChange={(event) => {
                                    setPrice((event.target.value))
                                }} />
                            </div>
                            <button className='trait-shop__hover-animated-btn' disabled={disableBtn} onClick={() => listTrait(collection, publicKey, trait, price, numberOfTraits, refresh, setListTrait, setDisableBtn)}>
                                <span>List Now</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const DelistTraitModal = ({ trait, setDelistTrait }) => {
    const [numberOfTraits, setNumberOfTraits] = useState(1)
    const [disableBtn, setDisableBtn] = useState(false)
    const { collection, refresh } = useContext(TraitContext)
    const { publicKey } = useWallet()
    if (trait) {
        return (
            <div className='modal-wrapper'>
                <div className='modal-bg' onClick={() => setDelistTrait()}></div>
                <div className='modal-container'>
                    <div className='modal'>
                        <form id='trait-shop__list-trait-form' className='utility__form' onSubmit={(event) => event.preventDefault()}>
                            <div className='field required'>
                                <div className='label'>Number of traits to delist</div>
                                <input className='text-input' value={numberOfTraits} type='number' onChange={(event) => {
                                    setNumberOfTraits(Number(event.target.value))
                                }} />
                            </div>
                            <button className='trait-shop__hover-animated-btn' disabled={disableBtn} onClick={() => delistTrait(collection, publicKey, trait, numberOfTraits, refresh, setDelistTrait, setDisableBtn)}>
                                <span>Delist Now</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const ApplyTrait = ({ trait, setApplyTrait }) => {
    const { nfts, refresh, collection } = useContext(TraitContext)
    const [activeNft, setActiveNft] = useState()
    const [disableBtn, setDisableBtn] = useState(false)

    const { publicKey, signTransaction } = useWallet()
    const {connection} = useConnection()

    if (trait) {
        if (activeNft) {
            return (
                <div className='modal-wrapper'>
                    <div className='modal-bg' onClick={() => setApplyTrait()}></div>
                    <div className='modal-container'>
                        <div className='modal' style={{ padding: '1rem' }}>
                            <button className='trait-shop__hover-animated-btn' onClick={() => setActiveNft()} style={{ margin: '0.5rem 0px', width: 'fit-content' }}>
                                <span>Back</span>
                            </button>

                            <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap', gap: '1rem' }}>
                                <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: '1.5rem' }}>Original Image</div>
                                    <LazyLoadImage src={activeNft.image} alt={activeNft.mintAddress} style={{ width: '200px', maxWidth: '50vw' }} />
                                </div>
                                <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: '1.5rem' }}>Updated Preview</div>
                                    <UpdatedTrait activeNft={activeNft} trait={trait} />
                                </div>
                            </div>
                            <button className='trait-shop__hover-animated-btn' onClick={() => applyTrait(collection, publicKey, trait, refresh, setActiveNft, setApplyTrait, activeNft.mintAddress, setDisableBtn, connection, signTransaction)} disabled={disableBtn}>
                                <span>Apply Now</span>
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='modal-wrapper'>
                    <div className='modal-bg' onClick={() => setApplyTrait()}></div>
                    <div className='modal-container'>
                        <div className='modal' style={{ padding: '1rem' }}>
                            <div className='trait-shop__listed-trait-category__title'>
                                Apply {trait.traitValue}
                            </div>
                            <div className='trait-shop__listed-trait-apply__form'>
                                {!nfts.length ? <div style={{ width: '100%', color: 'white', fontSize: '1.2rem', textAlign: 'center', margin: '0px 0px 0.5rem 0px' }}>
                                    You don't own any nft of this collection.
                                </div> : <>
                                    <div style={{ width: '100%', color: 'white', fontSize: '1.2rem', textAlign: 'center', margin: '0px 0px 0.5rem 0px' }}>
                                        Select NFT
                                    </div>
                                    {nfts.map((x, i) => {
                                        return (<div key={i} style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row' }}>
                                            <img src={x.image} alt={x.mintAddress} onClick={() => setActiveNft(x)} />
                                        </div>)
                                    })}</>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}


const UpdatedTrait = ({ activeNft, trait }) => {
    const { collection } = useContext(TraitContext)
    const [traits, setTraits] = useState()
    const [, setError] = useState('')

    useEffect(() => {
        async function updateNftMetadata() {
            try {
                const metadataReq = await fetch(activeNft.uri)
                const metadata = await metadataReq.json()
                let traits = []
                traits.push({
                    trait_type: trait.owned[0].traitCategory,
                    value: trait.traitValue
                })
                activeNft.updatedTraits.forEach(x => {
                    if (!traits.find(z => z.trait_type === x.trait_type)) {
                        traits.push(x)
                    }
                })

                metadata.attributes.forEach(x => {
                    if (!traits.find(z => z.trait_type === x.trait_type)) {
                        traits.push(x)
                    }
                })
                traits = traits.sort((b, a) => collection.layeringOrder.indexOf(b.trait_type) - collection.layeringOrder.indexOf(a.trait_type))

                traits = traits.map(x => {
                    console.log(x)
                    let traitCategory = collection.traitCategories.find(z => z.category === x.trait_type)
                    if (!traitCategory) setError(`Trait category ${x.trait_type} not found`)
                    let trait = traitCategory.traits.find(z => z.traitValue === x.value)
                    if (!trait) setError(`Trait not found - ${!x.value}`)
                    return trait.image
                })
                console.log(traits)
                setTraits(traits)
            } catch (err) {
                console.log(err)
                updateNftMetadata()
            }
        }
        updateNftMetadata()
    }, [activeNft, collection, trait])
    if (traits) {
        return (
            <div style={{ position: 'relative', width: '200px', aspectRatio: '1 / 1', maxWidth: '50vw' }}>
                {traits.map((x, i) => {
                    return (<img src={x} key={i} alt={x.traitValue} style={{ width: '100%', position: 'absolute' }} />)
                })}
            </div>
        )
    }
}