import { ThemeProvider } from '@mui/material'
import { useWallet } from '@solana/wallet-adapter-react'
import { SnackbarProvider } from 'notistack'
import React, { createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TraitTabsPanel from './components/trait-shop/TraitTabsPanel'
import Navbar from './components/utility/other-utility/Navbar'
import Preloader from './components/utility/other-utility/Preloader'
import Theme from './components/utility/other-utility/Theme'
import { updateCollection, updateListedTraits, updateMyTraits } from './javascript/traitShop'
import { updateNfts } from './javascript/utility'

export default function App() {
    const params = useParams()
    const [collection, setCollection] = useState()
    const [listedTraits, setListedTraits] = useState()
    const [myTraits, setMyTraits] = useState()
    const { publicKey } = useWallet()
    const [nfts, setNfts] = useState([])
    const refresh = (updateNft) => {
        updateCollection(setCollection, params.id)
        updateListedTraits(setListedTraits, params.id)
        updateMyTraits(setMyTraits, params.id, publicKey)
        if (updateNft) {
            updateNfts(setNfts, params.id, publicKey)
        }
    }
    useEffect(() => {
        refresh(true)
    }, [params.id, publicKey])
    if (!collection || !listedTraits || !myTraits || !nfts) {
        return (
            <div className='utility__loader-container'>
                <Preloader />
            </div>
        )
    } else {
        return (

            <ThemeProvider theme={Theme()}>
                <TraitContext.Provider value={{
                    collection: collection,
                    listedTraits: listedTraits,
                    myTraits: myTraits,
                    nfts: nfts,
                    refresh: refresh,
                }}>
                    <SnackbarProvider
                        autoHideDuration={5000}
                        maxSnack={5}
                        // action={key => <Button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', padding: '0.1rem', minWidth: 'fit-content'}} onClick={() => closeSnackbar(key)}><CloseButton id={key} /></Button>}
                        className={'utility__snack-bar'}
                    />

                    <Navbar />
                    <TraitTabsPanel />
                </TraitContext.Provider>
            </ThemeProvider>
        )
    }
}

export const TraitContext = createContext({
    collection: undefined,
    listedTraits: undefined,
    myTraits: undefined,
    nfts: undefined,
    refresh: undefined
})