import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress } from "@solana/spl-token"
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js"
import { enqueueSnackbar } from "notistack"
import config from "../config"

export const mintTrait = async (collection, publicKey, trait, signTransaction, connection, category, refresh, setDisableBtn) => {
    try {
        setDisableBtn(true)
        const tx = new Transaction()
        if (trait.mint?.data?.price?.solPayment) {
            tx.add(SystemProgram.transfer({
                fromPubkey: publicKey,
                lamports: Math.round(trait.mint?.data?.price?.solPayment?.amount * LAMPORTS_PER_SOL),
                toPubkey: new PublicKey(collection.fundsWallet)
            }))
        }
        if (trait.mint?.data?.price?.tokenPayment) {
            const tokenPayment = trait.mint?.data?.price?.tokenPayment
            const mintToken = new PublicKey(tokenPayment.tokenAddress)
            const recipientAddress = new PublicKey(collection.fundsWallet)

            const associatedTokenFrom = await getAssociatedTokenAddress(
                mintToken,
                publicKey
            );
            const fromAccount = await getAccount(connection, associatedTokenFrom);
            const associatedTokenTo = await getAssociatedTokenAddress(
                mintToken,
                recipientAddress
            );

            if (!(await connection.getAccountInfo(associatedTokenTo))) {
                const instruction = createAssociatedTokenAccountInstruction(
                    publicKey,
                    associatedTokenTo,
                    recipientAddress,
                    mintToken
                )
                tx.add(instruction)
            }
            const instruction2 = createTransferInstruction(
                fromAccount.address,
                associatedTokenTo,
                publicKey,
                Math.round(tokenPayment.amount * (tokenPayment.decimals || LAMPORTS_PER_SOL))
            )
            tx.add(instruction2)
        }
        const blockHash = await connection.getLatestBlockhash();
        tx.feePayer = publicKey;
        tx.recentBlockhash = blockHash.blockhash;

        const signed = await signTransaction(tx);
        const signature = await connection.sendRawTransaction(signed.serialize());
        await connection.confirmTransaction(signature, "processed");
        async function sendReq() {
            const req = await fetch(`${config.apiUrl}/mint-trait`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    projectId: collection.projectId,
                    publicKey: publicKey,
                    transactionId: signature,
                    traitId: trait.traitId
                })
            })
            const reqJson = await req.json()
            if (reqJson.message === 'Transaction not found') {
                const reqJson = await sendReq()
                return reqJson
            }
            return reqJson
        }
        const reqJson = await sendReq()
        if (!reqJson.status) {
            setDisableBtn(false)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
        } else {
            refresh()
            setDisableBtn(false)
            enqueueSnackbar({ message: `Successfully minted ${trait.traitValue} ${category}`, variant: 'success' })
        }
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        console.log(err)
    }
}
export const listTrait = async (collection, publicKey, trait, price, numberOfTraits, refresh, setListTrait, setDisableBtn) => {
    try {
        setDisableBtn(true)
        const traitAddresses = trait.owned.filter(x => !x.listed?.status).slice(0, numberOfTraits)
        const req = await fetch(`${config.apiUrl}/list-trait`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: collection.projectId,
                publicKey: publicKey,
                price: { solPayment: { amount: price } },
                traitAddresses: traitAddresses.map(x => x.traitAddress)
            })
        })
        const reqJson = await req.json()
        if (!reqJson.status) {
            setDisableBtn(false)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
        } else {
            refresh()
            setListTrait()
            setDisableBtn(false)
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
        }
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        console.log(err)
    }
}

export const delistTrait = async (collection, publicKey, trait, numberOfTraits, refresh, setDelistTrait, setDisableBtn) => {
    try {
        setDisableBtn(true)
        const traitAddresses = trait.owned.filter(x => x.listed?.status).slice(0, numberOfTraits)
        const req = await fetch(`${config.apiUrl}/delist-trait`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: collection.projectId,
                publicKey: publicKey,
                traitAddresses: traitAddresses.map(x => x.traitAddress)
            })
        })
        const reqJson = await req.json()
        if (!reqJson.status) {
            setDisableBtn(false)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
        } else {
            refresh()
            setDelistTrait()
            setDisableBtn(false)
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
        }
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        console.log(err)
    }
}

export const buyListedTrait = async (collection, publicKey, trait, signTransaction, connection, category, refresh, setDisableBtn, disableBtn) => {
    try {
        if (disableBtn) return
        setDisableBtn(true)
        const tx = new Transaction()
        tx.add(SystemProgram.transfer({
            fromPubkey: publicKey,
            lamports: Math.round(trait.listed?.data?.price?.solPayment?.amount * LAMPORTS_PER_SOL),
            toPubkey: new PublicKey(trait.owner)
        }))
        const blockHash = await connection.getLatestBlockhash();
        tx.feePayer = publicKey;
        tx.recentBlockhash = blockHash.blockhash;

        const signed = await signTransaction(tx);
        const signature = await connection.sendRawTransaction(signed.serialize());
        await connection.confirmTransaction(signature, "processed");

        const req = await fetch(`${config.apiUrl}/buy-listed-trait`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: collection.projectId,
                publicKey: publicKey,
                transactionId: signature,
                traitAddress: trait.traitAddress
            })
        })
        const reqJson = await req.json()
        if (!reqJson.status) {
            setDisableBtn(false)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
        } else {
            refresh()
            setDisableBtn(false)
            enqueueSnackbar({ message: `Successfully bought ${trait.traitValue} ${category}`, variant: 'success' })
        }
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        console.log(err)
    }
}


export const updateCollection = async (setCollection, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-collection`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setCollection(reqJson.data)
        else setCollection()
    } catch (err) {
        console.log(err)
        setCollection()
    }
}
export const updateListedTraits = async (setListedTraits, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-traits`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                query: { "projectId": projectId, 'listed.status': true }
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setListedTraits(reqJson.data)
    } catch (err) {
        console.log(err)
        setListedTraits()
    }
}

export const updateMyTraits = async (setMyTraits, projectId, publicKey) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-traits`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                query: { owner: publicKey, projectId: projectId }
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setMyTraits(reqJson.data)
    } catch (err) {
        console.log(err)
        setMyTraits()
    }
}


export const applyTrait = async (collection, publicKey, trait, refresh, setActiveNft, setApplyTrait, mintAddress, setDisableBtn, connection, signTransaction) => {
    try {
        setDisableBtn(true)
        const tx = new Transaction()
        tx.add(SystemProgram.transfer({
            fromPubkey: publicKey,
            lamports: Math.round(0.002 * LAMPORTS_PER_SOL),
            toPubkey: new PublicKey(collection.data.bundlrWallet)
        }))
        if (collection.data.fees?.applyTrait) {
            if (collection.data.fees.applyTrait.solPayment) {
                tx.add(SystemProgram.transfer({
                    fromPubkey: publicKey,
                    lamports: Math.round(collection.data.fees.applyTrait.solPayment.amount * LAMPORTS_PER_SOL),
                    toPubkey: new PublicKey(collection.data.feesWallet)
                }))
            }
        }
        const blockHash = await connection.getLatestBlockhash();
        tx.feePayer = publicKey;
        tx.recentBlockhash = blockHash.blockhash;

        const signed = await signTransaction(tx);
        const signature = await connection.sendRawTransaction(signed.serialize());
        await connection.confirmTransaction(signature, "processed");

        const req = await fetch(`${config.apiUrl}/apply-trait`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: collection.projectId,
                publicKey: publicKey,
                traitAddress: trait.owned[0].traitAddress,
                mintAddress: mintAddress
            })
        })
        const reqJson = await req.json()
        if (!reqJson.status) {
            setDisableBtn(false)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
        } else {
            refresh(true)
            setActiveNft()
            setApplyTrait()
            setDisableBtn(false)
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
        }
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        console.log(err)
    }
}

