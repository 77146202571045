import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { useContext, useEffect, useState } from 'react'
import { TraitContext } from '../App'
import { buyListedTrait } from '../javascript/traitShop'
import './../css/traitShop.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
export default function TraitShop() {
    const { listedTraits, collection } = useContext(TraitContext)

    const [listedTraitsToShow, setListedTraitsToShow] = useState()
    const [activeTraitId, setActiveTraitId] = useState()
    useEffect(() => {
        if (listedTraits) {
            let traits = []
            listedTraits.forEach((x, i) => {
                const traitInCollection = collection.traitCategories.find(y => y.category === listedTraits[i].traitCategory)?.traits?.find(y => y.traitValue === listedTraits[i].traitValue)
                let traitCategory = traits.find(x => x.traitCategory === listedTraits[i].traitCategory)
                if (!traitCategory) {
                    traits.push({
                        traitCategory: listedTraits[i].traitCategory,
                        traits: []
                    })
                    traitCategory = traits.find(x => x.traitCategory === listedTraits[i].traitCategory)
                }
                let traitValue = traitCategory.traits.find(x => x.traitValue === listedTraits[i].traitValue)
                if (!traitValue) {
                    traitCategory.traits.push({
                        traitId: traitInCollection.traitId,
                        traitValue: listedTraits[i].traitValue,
                        listed: [],
                        image: traitInCollection.image
                    })
                    traitValue = traitCategory.traits.find(x => x.traitValue === listedTraits[i].traitValue)
                }
                traitValue.listed.push(listedTraits[i])
            })
            setListedTraitsToShow(traits)
        }
    }, [listedTraits, collection.traitCategories])


    if (!listedTraitsToShow) {
        return (<div>

        </div>)
    } else if(!listedTraitsToShow.length) {
        return (
            <div className='utility__centered-text'>
                There is no trait listed
            </div>
        )
    } else {
        return (
            <>
                <Offers traitId={activeTraitId} listedTraitsToShow={listedTraitsToShow} setActiveTraitId={setActiveTraitId} />
                <div className='trait-shop__listed-traits-categories__container'>
                    {listedTraitsToShow.map((x, i) => {
                        return (
                            <div className='trait-shop__listed-trait-category__container' key={i}>
                                <div className='trait-shop__listed-trait-category__title'>{x.traitCategory}</div>
                                <div className='trait-shop__listed-traits__container'>
                                    {x.traits.map((y, i) => {
                                        return (
                                            <div key={i} className='trait-shop__listed-trait__container'>
                                                <LazyLoadImage className='trait-shop__listed-trait__img' src={y.image} alt={y.traitValue} />
                                                <div className='trait-shop__listed-trait__info-container'>
                                                    <div className='trait-shop__listed-trait__name'>{y.traitValue}</div>
                                                    <div className='trait-shop__listed-trait__info-box'>
                                                        <div className='trait-shop__listed-trait__info-box-title'> </div>
                                                        <div className='trait-shop__listed-trait__info-box-info'></div>
                                                        <button className='trait-shop__hover-animated-btn' onClick={() => setActiveTraitId(y.traitId)}>
                                                            <span>VIEW OFFERS</span><i></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }
}

const Offers = ({ listedTraitsToShow, traitId, setActiveTraitId }) => {
    const { collection, refresh } = useContext(TraitContext)
    const { publicKey, signTransaction } = useWallet()
    const { connection } = useConnection()
    const [disableBtn, setDisableBtn] = useState(false)
    if (traitId && listedTraitsToShow.find(x => x.traits.find(y => y.traitId === traitId))) {
        return (
            <div className='modal-wrapper'>
                <div className='modal-bg' onClick={() => setActiveTraitId()}></div>
                <div className='modal-container'>
                    <div className='modal' style={{ padding: '1rem' }}>
                        <div className='trait-shop__listed-trait-category__title'>
                            {listedTraitsToShow.find(x => x?.traits.find(y => y.traitId === traitId))?.traitCategory} - {listedTraitsToShow.find(x => x?.traits.find(y => y.traitId === traitId)).traits?.find(y => y.traitId === traitId)?.traitValue}
                        </div>
                        <TableContainer component={Paper} style={{ width: '400px', maxWidth: '90vw', background: '#1c0b35', border: '2px solid #6d71ff', marginTop: '0.5rem' }}>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{
                                            padding: '0.2rem 1rem',
                                            color: 'white',
                                            fontSize: '1.3rem',
                                            textTransform: 'uppercase'
                                        }}>Seller</TableCell>
                                        <TableCell style={{
                                            padding: '0.2rem 1rem',
                                            color: 'white',
                                            fontSize: '1.3rem',
                                            textTransform: 'uppercase'
                                        }} align="right">Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {listedTraitsToShow.find(x => x.traits.find(y => y.traitId === traitId))?.traits.find(x => x.traitId === traitId).listed?.filter(x => x.seller !== publicKey?.toString()).sort((b, a) => b?.listed?.data?.price?.solPayment?.amount - a?.listed?.data?.price?.solPayment?.amount)?.map((x, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                disabled={disableBtn}
                                                onClick={() => buyListedTrait(collection, publicKey, x, signTransaction, connection, listedTraitsToShow.find(x => x.traits.find(y => y.traitId === traitId))?.category, refresh, setDisableBtn, disableBtn)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                className='trait-shop__listed-trait__buy-row'
                                            >
                                                <TableCell component="th" scope="row" style={{
                                                    padding: '0.2rem 1rem',
                                                    color: 'white',
                                                    border: '0px'
                                                }}>
                                                    {x.owner}
                                                </TableCell>
                                                <TableCell align="right" style={{
                                                    padding: '0.2rem 1rem',
                                                    color: 'white',
                                                    textTransform: 'uppercase',
                                                    border: '0px'
                                                }}>{x?.listed?.data?.price?.solPayment?.amount} SOL</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>

                            </Table>
                        </TableContainer>

                    </div>
                </div>
            </div>
        )
    }
}
export const myTraits = [
    {
        traitValue: 'Light Yellow',
        projectId: 'teensbuffbunny',
        traitCategory: 'Background',
        owner: '7myw2aAIjaMEW2alKAeiOAPLkIAjanamnaj',
        listed: {
            status: true, data: {
                price: {
                    solPayment: {
                        amount: 0.02
                    }
                }
            }
        }
    },
]
export const traitsListed = [
    {
        traitValue: 'Light Yellow',
        projectId: 'teensbuffbunny',
        traitCategory: 'Background',
        owner: '7myw2aAIjaMEW2alKAeiOAPLkIAjanamnaj',
        listed: {
            status: true, data: {
                price: {
                    solPayment: {
                        amount: 0.02
                    }
                }
            }
        }
    },
    {
        traitValue: 'Pink',
        projectId: 'teensbuffbunny',
        traitCategory: 'Background',
        owner: '7myw2aAIjaMEW2alKAeiOAPLkIAjanamnaj',
        listed: {
            status: true, data: {
                price: {
                    solPayment: {
                        amount: 0.04
                    }
                }
            }
        }
    },
    {
        traitValue: 'Light Green',
        projectId: 'teensbuffbunny',
        owner: '7myw2aAIjaMEW2alKAeiOAPLkIAjanamnaj',
        traitCategory: 'Body',
        listed: {
            status: true, data: {
                price: {
                    solPayment: {
                        amount: 0.04
                    }
                }
            }
        }
    },
    {
        traitValue: 'Light Green',
        projectId: 'teensbuffbunny',
        owner: '7myw2aAIjaMEW2alKAeiOAPLkIAjanamnaj',
        traitCategory: 'Body',
        listed: {
            status: true, data: {
                price: {
                    solPayment: {
                        amount: 0.02
                    }
                }
            }
        }
    }
]

export const collections = [
]