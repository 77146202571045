import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { useContext, useState } from 'react'
import { TraitContext } from '../App'
import { mintTrait } from '../javascript/traitShop'
import { nFormatter } from '../javascript/utility'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function MintTraits() {
    const { collection, refresh } = useContext(TraitContext)
    const [disableBtn, setDisableBtn] = useState(false)
    const { publicKey, signTransaction } = useWallet()
    const { connection } = useConnection()
    return (
        <div>
            <div className='trait-shop__listed-traits-categories__container'>
                {collection.traitCategories.map(x => {
                    let newTraits = { ...x }
                    newTraits.traits = x.traits.filter(y => y.mint?.status)
                    return newTraits
                }).filter(x => x.traits?.length).map((x, i) => {
                    return (
                        <div className='trait-shop__listed-trait-category__container' key={i}>
                            <div className='trait-shop__listed-trait-category__title'>{x.category}</div>
                            <div className='trait-shop__listed-traits__container'>
                                {x.traits.map((y, i) => {
                                    return (
                                        <div key={i} className='trait-shop__listed-trait__container'>
                                            <LazyLoadImage className='trait-shop__listed-trait__img' src={y.image} alt={y.traitValue} />
                                            <div className='trait-shop__listed-trait__info-container'>
                                                <div className='trait-shop__listed-trait__name'>{y.traitValue}</div>
                                                <div className='trait-shop__listed-trait__info-box'>
                                                    {y?.mint?.supply && (<SupplyBar supply={y.mint.supply} minted={y.mint.minted} />)}


                                                    <button className='trait-shop__hover-animated-btn'>
                                                        <span>{y?.mint?.data?.price?.solPayment && `${nFormatter(y?.mint?.data?.price?.solPayment?.amount)} SOL`} {(y?.mint?.data?.price?.tokenPayment && y?.mint?.data?.price?.solPayment) && '+'} {y?.mint?.data?.price?.tokenPayment && `${y?.mint?.data?.price?.tokenPayment?.amount} ${y?.mint?.data?.price?.tokenPayment?.symbol}`}</span>
                                                    </button>

                                                    <button className='trait-shop__hover-animated-btn' disabled={disableBtn} onClick={() => mintTrait(collection, publicKey, y, signTransaction, connection, x.category, refresh, setDisableBtn)}>
                                                        <span>MINT</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


const SupplyBar = ({ minted, supply }) => {
    return (
        <div style={{ padding: '5px' }}>
            <div style={{ position: 'relative', background: '#363636e8', minHeight: '5px' }}>
                <div style={{ width: `${minted / supply * 100}%`, position: 'absolute', left: 0, height: '100%', background: '#00ffff' }}>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'row wrap', fontSize: '0.8rem', padding: '3px 0px 0px 0px', textTransform: 'uppercase' }}>
                <div>Remaining</div>
                <div>
                    {supply - minted}/{supply}
                </div>
            </div>
        </div>
    )
}