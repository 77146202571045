import * as React from 'react';
import TraitShop from '../../pages/TraitShop';
import './../../css/material.css'
import { Button } from '@mui/material';
import MyTraits from '../../pages/MyTraits';
import MintTraits from '../../pages/MintTraits';
export default function TraitTabsPanel() {
    const [activeTab, setActiveTab] = React.useState('Listed')

    return (
        <>
            <div className="trait-shop__panel-button-container">
                <Button className={`trait-shop__panel-button ${activeTab === 'Listed' ? "trait-shop__panel-button-active" : ""}`} onClick={() => setActiveTab('Listed')}>Listed Traits</Button>
                <Button className={`trait-shop__panel-button ${activeTab === 'Mint' ? "trait-shop__panel-button-active" : ""}`} onClick={() => setActiveTab('Mint')}>Mint Traits</Button>
                <Button className={`trait-shop__panel-button ${activeTab === 'MyTraits' ? "trait-shop__panel-button-active" : ""}`} onClick={() => setActiveTab('MyTraits')}>My Traits</Button>
            </div>
            <div id="trait-shop__panel-Listed" className="trait-shop__panel-content" style={{ display: activeTab === 'Listed' ? 'block' : 'none' }}>
                <TraitShop/>
            </div>

            <div id="trait-shop__panel-Mint" className="trait-shop__panel-content" style={{ display: activeTab === 'Mint' ? 'block' : 'none' }}>
                <MintTraits/>
            </div>

            <div id="trait-shop__panel-MyTraits" className="trait-shop__panel-content" style={{ display: activeTab === 'MyTraits' ? 'block' : 'none' }}>
                <MyTraits/>
            </div>
        </>
    )
}