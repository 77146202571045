import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/utility.css'
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WalletAdapterProvider from './components/utility/wallet-adapter/WalletAdapterProvider'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <WalletAdapterProvider>
      <Routes>
        <Route path='/:id/*' element={<App />} />
        <Route path='/*' element={<App />} />
      </Routes>
      </WalletAdapterProvider>
  </BrowserRouter>
);
