import React, { useContext } from 'react'
import { TraitContext } from '../../../App'
import ConnectButton from '../wallet-adapter/ConnectButton'

export default function Navbar() {
    const { collection } = useContext(TraitContext)
    return (
        <div className='navbar'>
            <div className='navbar__logo-container'>
                <img className='navbar__logo' src={collection.logo} alt='logo' />
            </div>
            <ConnectButton />
        </div>
    )
}
